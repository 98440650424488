import { SearchResult, ticketApi, TicketSearchRequest, TicketSearchResult } from "@/api/tickets";
import { defineStore } from "pinia";
import Sortable from "sortablejs";
import { Ref } from "vue";

export type TicketSearchState = {
    searching: boolean;
    searched: boolean;
    results: TicketSearchResult[];
    total: number;
    request: TicketSearchRequest;
    options: TicketSearchOptions;
    shownFields: ShownFields;
};

export type TicketSearchOptions = {
    page: number | null;
    perPage: number | null;
};

export type ShownFields = {
    project: boolean;
    assignees: boolean;
    reporter: boolean;
    priorities: boolean;
    resolutions: boolean;
    labels: boolean;
    statuses: boolean;
    types: boolean;
    affectsVersions: boolean;
    fixVersions: boolean;
    sortBy: boolean;
};

export type SortableType = Sortable;

export interface SortableEvent {
    oldIndex: number;
    newIndex: number;
}

export enum SearchComponentIndex {
    ProjectSearch = 0,
    AssigneeSearch,
    ReporterSearch,
    PrioritySearch,
    ResolutionSearch,
    LabelSearch,
    StatusSearch,
    TypeSearch,
    AffectVersionSearch,
    FixVersionSearch,
    SortBy,
}

export type SearchItem<T> = {
    title: string;
    component: T;
    itemIndex: SearchComponentIndex;
    visible: Ref<boolean>;
    allowed?: Ref<boolean>;
};

export const useTicketSearchStore = defineStore("ticketSearch", {
    state: (): TicketSearchState => ({
        searching: false,
        searched: false,
        results: [],
        total: 0,
        request: {
            text: null,
            project: null,
            assignee: null,
            assigned: null,
            reporter: null,
            priorities: null,
            resolutions: null,
            resolved: null,
            affectVersions: null,
            hasAffectVersion: null,
            fixVersions: null,
            hasFixVersion: null,
            order: null,
            direction: null,
            dueDate: null,
            statuses: null,
            labels: null,
            types: null,
        },
        options: {
            page: null,
            perPage: null,
        },
        shownFields: {
            project: true,
            assignees: false,
            reporter: false,
            priorities: false,
            resolutions: false,
            labels: false,
            statuses: false,
            types: false,
            affectsVersions: false,
            fixVersions: false,
            sortBy: false,
        },
    }),
    actions: {
        async doSearch(
            request: TicketSearchRequest,
            page: number | null,
            perPage: number | null,
            storeRequest = true,
            deleted = false
        ) {
            // Fetch the data from the server
            this.$state.searching = true;
            let result: SearchResult | null = null;
            if (deleted) {
                result = await ticketApi.searchDeleted(request, page, perPage);
            } else {
                result = await ticketApi.search(request, page, perPage);
            }

            // Update the state with the new data
            this.$state.searched = true;
            this.$state.results = result.tickets;
            this.$state.total = result.total;

            this.$state.options.page = page;
            this.$state.options.perPage = perPage;

            if (storeRequest) {
                this.$state.request = request;
            }
            if (this.getShowProject && !this.$state.shownFields.project) this.showProject(true);
            if (this.getShowAssignees && !this.$state.shownFields.assignees)
                this.showAssignees(true);
            if (this.getShowReporter && this.$state.shownFields.reporter) this.showReporter(true);
            if (this.getShowPriorities && this.$state.shownFields.priorities)
                this.showPriorities(true);
            if (this.getShowResolutions && this.$state.shownFields.resolutions)
                this.showResolutions(true);
            if (this.getShowLabels && this.$state.shownFields.labels) this.showLabels(true);
            if (this.getShowStatuses && this.$state.shownFields.statuses) this.showStatuses(true);
            if (this.getShowTypes && this.$state.shownFields.types) this.showTypes(true);
            if (this.getShowAffectsVersions && this.$state.shownFields.affectsVersions)
                this.showAffectsVersions(true);
            if (this.getShowFixVersions && this.$state.shownFields.fixVersions)
                this.showFixVersions(true);
            if (this.getShowSortBy && this.$state.shownFields.sortBy) this.showSortBy(true);

            this.$state.searching = false;
        },
        showProject(intake: boolean) {
            this.$state.shownFields.project = intake;
        },
        showAssignees(intake: boolean) {
            this.$state.shownFields.assignees = intake;
        },
        showReporter(intake: boolean) {
            this.$state.shownFields.reporter = intake;
        },
        showPriorities(intake: boolean) {
            this.$state.shownFields.priorities = intake;
        },
        showResolutions(intake: boolean) {
            this.$state.shownFields.resolutions = intake;
        },
        showLabels(intake: boolean) {
            this.$state.shownFields.labels = intake;
        },
        showStatuses(intake: boolean) {
            this.$state.shownFields.statuses = intake;
        },
        showTypes(intake: boolean) {
            this.$state.shownFields.types = intake;
        },
        showAffectsVersions(intake: boolean) {
            this.$state.shownFields.affectsVersions = intake;
        },
        showFixVersions(intake: boolean) {
            this.$state.shownFields.fixVersions = intake;
        },
        showSortBy(intake: boolean) {
            this.$state.shownFields.sortBy = intake;
        },
        clearRequest() {
            this.$state.request = {
                text: null,
                project: null,
                assignee: null,
                assigned: null,
                reporter: null,
                priorities: null,
                resolutions: null,
                resolved: null,
                affectVersions: null,
                hasAffectVersion: null,
                fixVersions: null,
                hasFixVersion: null,
                order: null,
                direction: null,
                dueDate: null,
                statuses: null,
                labels: null,
                types: null,
            };
        },
        clearState() {
            this.clearRequest();
            this.results = [];
            this.showProject(true);
            this.showAssignees(false);
            this.showReporter(false);
            this.showPriorities(false);
            this.showResolutions(false);
            this.showLabels(false);
            this.showStatuses(false);
            this.showTypes(false);
            this.showAffectsVersions(false);
            this.showFixVersions(false);
            this.showSortBy(false);
        },
    },
    getters: {
        getShowProject(state) {
            return state.request.project != null || state.shownFields.project;
        },
        getShowAssignees(state) {
            return (
                state.request.assigned != null ||
                state.request.assignee != null ||
                state.shownFields.assignees
            );
        },
        getShowReporter(state) {
            return state.request.reporter != null || state.shownFields.reporter;
        },
        getShowPriorities(state) {
            return state.request.priorities != null || state.shownFields.priorities;
        },
        getShowResolutions(state) {
            return (
                state.request.resolutions != null ||
                state.request.resolved != null ||
                state.shownFields.resolutions
            );
        },
        getShowLabels(state) {
            return state.request.labels != null || state.shownFields.labels;
        },
        getShowStatuses(state) {
            return state.request.statuses != null || state.shownFields.statuses;
        },
        getShowTypes(state) {
            return state.request.types != null || state.shownFields.types;
        },
        getShowAffectsVersions(state) {
            return (
                state.request.affectVersions != null ||
                state.request.hasAffectVersion != null ||
                state.shownFields.affectsVersions
            );
        },
        getShowFixVersions(state) {
            return (
                state.request.fixVersions != null ||
                state.request.hasFixVersion != null ||
                state.shownFields.fixVersions
            );
        },
        getShowSortBy(state) {
            return (
                state.request.order != null ||
                state.request.direction != null ||
                state.shownFields.sortBy
            );
        },
    },
});
