import type { OrganizationPermission } from "@/api/organizations";
import type { ProjectPermission } from "@/api/projects";
import { teamApi, type Team, type TeamId } from "@/api/teams";
import type { UserId } from "@/api/users";
import { isDefined } from "@/utils";
import { defineStore } from "pinia";

export type TeamsState = {
    teams: Map<TeamId, Team> | undefined;
    users: Map<UserId, TeamId[]> | undefined;
};

export const useTeamsStore = defineStore("teams", {
    state: (): TeamsState => ({
        teams: undefined,
        users: undefined,
    }),
    getters: {
        get(state) {
            return (id: TeamId): Readonly<Team | undefined> => state.teams?.get(id);
        },
        getTeams(state) {
            return (id: UserId): Readonly<Team[] | undefined> =>
                state.users
                    ?.get(id)
                    ?.map((t) => state.teams?.get(t))
                    .filter(isDefined);
        },
    },
    actions: {
        async load(force = false) {
            await this.loadTeams(force);
        },
        async loadTeams(force = false) {
            if (!force && this.teams) {
                return;
            }

            const teams = await teamApi.getTeams();
            this.teams = new Map(teams.map((t) => [t.id, t]));

            if (this.users) {
                this.users.clear();
            } else {
                this.users = new Map();
            }

            teams.forEach((t) => {
                t.users.forEach((u) => {
                    const teams = this.users!.get(u);
                    if (teams) {
                        teams.push(t.id);
                    } else {
                        this.users!.set(u, [t.id]);
                    }
                });
            });
        },

        async addUser(id: TeamId, users: UserId[]) {
            await teamApi.addUsers(id, users);

            const team = this.teams?.get(id);
            if (team) {
                users.forEach((user) => {
                    team.users.push(user);
                });
            }

            users.forEach((user) => {
                const teams = this.users?.get(user);
                if (teams) {
                    teams.push(id);
                } else {
                    this.users?.set(user, [id]);
                }
            });
        },
        async removeUser(id: TeamId, user: UserId) {
            await teamApi.removeUser(id, user);

            const team = this.teams?.get(id);
            if (team) {
                const idx = team.users.indexOf(user);
                team.users.splice(idx, 1);
            }

            const teams = this.users?.get(user);
            if (teams) {
                const idx = teams.indexOf(id);
                teams.splice(idx, 1);
            }
        },

        async setPermissions(id: TeamId, permissions: ProjectPermission[]) {
            await teamApi.setPermissions(id, permissions);

            const team = this.teams?.get(id);
            if (team) {
                team.permissions = permissions;
            }
        },

        async setOrganizationPermissions(id: TeamId, permissions: OrganizationPermission[]) {
            await teamApi.setOrganizationPermissions(id, permissions);

            const team = this.teams?.get(id);
            if (team) {
                team.organizationPermissions = permissions;
            }
        },
    },
    debounce: {
        loadTeams: { ms: 100 },
    },
});
