import "pinia";
import { _ActionsTree, PiniaPluginContext, StoreActions } from "pinia";
import { debounce, debounceByParameters } from "@/utils";

declare module "pinia" {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    export interface DefineStoreOptionsBase<S, Store> {
        debounce?: Partial<
            Record<
                keyof StoreActions<Store>,
                {
                    ms: number;
                    byParameters?: boolean;
                }
            >
        >;
    }
}

export function debouncePlugin(context: PiniaPluginContext) {
    if (context.options.debounce) {
        return Object.keys(context.options.debounce).reduce(
            (debouncedActions: StoreActions<typeof context.store>, action) => {
                const debounceOptions = context.options.debounce![action] ?? { ms: 100 };
                const debounceFunc = debounceOptions.byParameters ? debounceByParameters : debounce;
                debouncedActions[action] = debounceFunc(
                    context.store[action] as (...args: unknown[]) => unknown,
                    debounceOptions.ms
                );
                return debouncedActions;
            },
            {}
        );
    } else {
        return undefined;
    }
}
