<template>
    <v-container>
        <v-expansion-panels multiple class="mt-2">
            <v-expansion-panel title="Assigned Tickets" value="tickets">
                <template #text>
                    <div v-if="assignedTickets.length == 0" class="mt-3">No tickets available</div>
                    <v-list v-else class="overflow-y-auto">
                        <v-list-item
                            v-for="ticket in assignedTickets"
                            :key="ticket.id"
                            class="mb-2"
                            :to="{ name: 'TicketView', params: { ticketId: ticket.key } }"
                            @click="navigateToTicket(ticket.key)"
                        >
                            <v-list-item-title>
                                {{ ticket.key }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ ticket.title }}
                            </v-list-item-subtitle>
                        </v-list-item>
                        <v-btn
                            color="primary"
                            class="mt-4 ml-4"
                            text="Go to the search page"
                            @click="navigateToSearch"
                        />
                    </v-list>
                </template>
            </v-expansion-panel>
            <v-expansion-panel
                v-for="organization in organizations"
                :key="organization.id"
                :title="organization.name"
                eager
            >
                <template #text>
                    <div v-if="getProjects(organization.id).length == 0" class="mt-3">
                        No projects available
                    </div>
                    <v-list v-else class="overflow-y-auto">
                        <v-list-item
                            v-for="project in getProjects(organization.id)"
                            :key="project.id"
                            class="mb-2"
                            @click="navigateToProjectSearch(project.id)"
                        >
                            <v-list-item-title>
                                {{ project.key }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ project.name }}
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </template>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>
<script setup lang="ts">
import { useAppBarItem } from "@/composables/appBar";
import { OrganizationId } from "@/api/organizations";
import { Project, ProjectId } from "@/api/projects";
import { useOrganizationsStore } from "@/store/organizations";
import { useProjectsStore } from "@/store/projects";
import { ref, onMounted, computed } from "vue";
import {
    TicketSearchResult,
    TicketSearchRequest,
    TicketSearchOrder,
    TicketSearchDirection,
    TicketId,
    TicketKey,
} from "@/api/tickets";
import { useAuthStore } from "@/store/auth";
import { useRouter } from "vue-router";
import { useTicketSearchStore } from "@/store/ticketSearch";

const organizations = computed(() =>
    Array.from(orgStore.$state.organizations ?? [], ([_, org]) => org)
);

const orgStore = useOrganizationsStore();
const projStore = useProjectsStore();
const ticketSearchStore = useTicketSearchStore();
const authStore = useAuthStore();
const router = useRouter();

const assignedTickets = ref<TicketSearchResult[]>([]);
const loadingTickets = ref(false);

async function load(): Promise<void> {
    await orgStore.loadOrganizations();
    await projStore.loadProjects();
    await loadAssignedTickets();
}

function getProjects(id: OrganizationId): Project[] {
    return projStore.getOrganizationProjects(id) ?? [];
}

async function loadAssignedTickets(): Promise<void> {
    if (authStore.user == null) {
        return;
    }

    loadingTickets.value = true;

    const searchRequest: TicketSearchRequest = {
        text: null,
        project: null,
        assignee: authStore.user.id,
        assigned: null,
        resolutions: null,
        labels: null,
        reporter: null,
        types: null,
        priorities: null,
        statuses: null,
        resolved: false,
        affectVersions: null,
        hasAffectVersion: null,
        fixVersions: null,
        hasFixVersion: null,
        dueDate: null,
        order: TicketSearchOrder.Priority,
        direction: TicketSearchDirection.Descending,
    };

    await ticketSearchStore.doSearch(searchRequest, null, null);
    assignedTickets.value = ticketSearchStore.$state.results;

    loadingTickets.value = false;
}

function navigateToTicket(ticketId: TicketId | TicketKey) {
    void router.push({ name: "TicketView", params: { ticketId } });
}

function navigateToProjectSearch(projectId: ProjectId) {
    const searchRequest: TicketSearchRequest = {
        text: null,
        project: projectId,
        assignee: null,
        assigned: null,
        resolutions: null,
        labels: null,
        reporter: null,
        types: null,
        priorities: null,
        statuses: null,
        resolved: false,
        affectVersions: null,
        hasAffectVersion: null,
        fixVersions: null,
        hasFixVersion: null,
        dueDate: null,
        order: null,
        direction: null,
    };
    ticketSearchStore.searched = false;
    ticketSearchStore.request = searchRequest;

    void router.push({ name: "TicketSearch" });
}

function navigateToSearch() {
    void router.push({ name: "TicketSearch" });
}

useAppBarItem(ref({ key: "Home", title: "Home" }));

onMounted(load);
</script>
