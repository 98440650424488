import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/AppHome.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/auth",
        component: () => import(/* webpackChunkName: "auth" */ "../views/AuthView.vue"),
        redirect: { name: "AuthLogin" },
        meta: {
            guest: true,
        },
        children: [
            {
                name: "AuthLogin",
                path: "login",
                component: () =>
                    import(/* webpackChunkName: "auth" */ "../views/auth/AuthLogin.vue"),
            },
            {
                name: "AuthWelcome",
                path: "welcome",
                props: (route) => ({ user: route.query["user"], token: route.query["token"] }),
                component: () =>
                    import(/* webpackChunkName: "auth" */ "../views/auth/AuthWelcome.vue"),
            },
            {
                name: "AuthForgot",
                path: "forgot",
                component: () =>
                    import(/* webpackChunkName: "auth" */ "../views/auth/AuthForgot.vue"),
            },
            {
                name: "AuthReset",
                path: "reset",
                props: (route) => ({ token: route.query["token"] }),
                component: () =>
                    import(/* webpackChunkName: "auth" */ "../views/auth/AuthReset.vue"),
            },
        ],
    },
    {
        name: "TicketSearch",
        path: "/tickets/search/:ticketId?/:table?/:list?",
        props: true,
        component: () =>
            import(/* webpackChunkName: "tickets" */ "../views/tickets/TicketSearch.vue"),
    },
    {
        name: "CreateTicket",
        path: "/tickets/create",
        props: true,
        component: () =>
            import(/* webpackChunkName: "tickets" */ "@/views/tickets/TicketCreate.vue"),
    },
    {
        name: "TicketView",
        path: "/tickets/:ticketId",
        props: true,
        component: () =>
            import(/* webpackChunkName: "tickets" */ "../views/tickets/TicketView.vue"),
    },
    {
        name: "TicketLists",
        path: "/lists/:listId?/:mode?",
        props: true,
        component: () =>
            import(/* webpackChunkName: "tickets" */ "../views/tickets/TicketList.vue"),
    },
    {
        name: "Admin",
        path: "/admin",
        component: () => import(/* webpackChunkName: "admin" */ "../views/AdminHome.vue"),
        children: [
            {
                name: "OrganizationList",
                path: "organizations",
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/admin/OrganizationList.vue"),
            },
            {
                name: "UserList",
                path: "users",
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/admin/UserList.vue"),
            },
            {
                name: "EmailTemplates",
                path: "email-templates",
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/admin/EmailTemplates.vue"),
            },
            {
                name: "Configs",
                path: "configs/:tab?",
                props: true,
                beforeEnter: (to, _from, next) => {
                    if (to.params["tab"]) {
                        next();
                    } else {
                        next({ ...to, params: { ...to.params, tab: "project configuration" } });
                    }
                },
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/admin/ConfigurationsList.vue"),
            },
            {
                name: "Audit Logs",
                path: "audit",
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/admin/AuditLogs.vue"),
            },
            {
                name: "Import Data",
                path: "import",
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/admin/ImportData.vue"),
            },
            {
                name: "Recovery",
                path: "recovery/:tab?",
                props: true,
                beforeEnter: (to, _from, next) => {
                    if (to.params["tab"]) {
                        next();
                    } else {
                        next({ ...to, params: { ...to.params, tab: "tickets" } });
                    }
                },
                component: () =>
                    import(/* webpackChunkName: "admin" */ "../views/admin/SoftDeleteRecovery.vue"),
            },
        ],
    },
    {
        name: "OrganizationView",
        path: "/organizations/:organizationId/:tab?",
        props: true,
        beforeEnter: (to, _from, next) => {
            if (to.params["tab"]) {
                next();
            } else {
                next({ ...to, params: { ...to.params, tab: "projects" } });
            }
        },
        component: () =>
            import(
                /* webpackChunkName: "organizations" */ "../views/organizations/OrganizationView.vue"
            ),
    },
    {
        name: "TeamView",
        path: "/teams/:teamId/:tab?",
        props: true,
        beforeEnter: (to, _from, next) => {
            if (to.params["tab"]) {
                next();
            } else {
                next({ ...to, params: { ...to.params, tab: "users" } });
            }
        },
        component: () =>
            import(/* webpackChunkName: "organizations" */ "../views/organizations/TeamView.vue"),
    },
    {
        name: "ProjectList",
        path: "/projects",
        component: () =>
            import(/* webpackChunkName: "projects" */ "../views/projects/ProjectList.vue"),
    },
    {
        name: "ProjectView",
        path: "/projects/:projectId/:tab?",
        props: true,
        beforeEnter: (to, _from, next) => {
            if (to.params["tab"]) {
                next();
            } else {
                next({ ...to, params: { ...to.params, tab: "overview" } });
            }
        },
        component: () =>
            import(/* webpackChunkName: "projects" */ "../views/projects/ProjectView.vue"),
    },
    {
        name: "UserView",
        path: "/users/:userId",
        props: true,
        component: () => import(/* webpackChunkName: "users" */ "../views/users/UserView.vue"),
    },
    {
        name: "Settings",
        path: "/settings/:tab?",
        props: true,
        beforeEnter: (to, _from, next) => {
            if (to.params["tab"]) {
                next();
            } else {
                next({ ...to, params: { ...to.params, tab: "profile" } });
            }
        },
        component: () =>
            import(/* webpackChunkName: "settings" */ "../views/settings/SettingsHome.vue"),
    },
    // TODO: Remove before release
    {
        name: "DesignTemplates",
        path: "/design/:tab?",
        props: true,
        beforeEnter: (to, _from, next) => {
            if (to.params["tab"]) {
                next();
            } else {
                next({ ...to, params: { ...to.params, tab: "buttons" } });
            }
        },
        component: () =>
            import(/* webpackChunkName: "design" */ "@v/admin/DesignTemplatesView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env["BASE_URL"]),
    routes,
});

export default router;
