import axios from "axios";

export enum EmailTemplateType {
    UserWelcome = 0,
    UserPasswordReset,
    UserNameReset,

    OrganizationNewUserToUser = 20,
    OrganizationNewUserToAdmins,
}

export function templateName(type: EmailTemplateType) {
    switch (type) {
        case EmailTemplateType.UserWelcome:
            return "User Welcome";
        case EmailTemplateType.UserPasswordReset:
            return "Password Reset";
        case EmailTemplateType.UserNameReset:
            return "User Name Reset";
        case EmailTemplateType.OrganizationNewUserToUser:
            return "Organization New User (User)";
        case EmailTemplateType.OrganizationNewUserToAdmins:
            return "Organization New User (Admins)";
    }
}

export function templateDescription(type: EmailTemplateType) {
    switch (type) {
        case EmailTemplateType.UserWelcome:
            return "Sent when a user is first added to Procyon.";
        case EmailTemplateType.UserPasswordReset:
            return "Sent when a user resets their password.";
        case EmailTemplateType.UserNameReset:
            return "Sent when a user's username is unavailable during import.";
        case EmailTemplateType.OrganizationNewUserToUser:
            return "Sent to a user when they're added to a organization.";
        case EmailTemplateType.OrganizationNewUserToAdmins:
            return "Sent to an organization's admins when a new user is added.";
        default:
            return null;
    }
}

export type EmailTemplate = {
    readonly type: EmailTemplateType;
    html: boolean;
    subject: string;
    body: string;
};

export type EmailApi = {
    getTemplates(): Promise<EmailTemplate[]>;
    editTemplate(
        type: EmailTemplateType,
        html: boolean,
        subject: string,
        body: string
    ): Promise<void>;
};

export const emailApi: EmailApi = {
    async getTemplates() {
        const { data } = await axios.get<EmailTemplate[]>("/api/v0/email-templates");

        return data;
    },
    async editTemplate(type, html, subject, body) {
        await axios.put(`/api/v0/email-templates/${type}`, {
            html,
            subject,
            body,
        });
    },
};
