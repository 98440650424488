import {
    AuditedActions,
    auditLogApi,
    AuditLogSearchRequest,
    AuditLogsSearchResult,
} from "@/api/auditLogs";
import { defineStore } from "pinia";

export type AuditLogsState = {
    search: {
        searched: boolean;
        request: AuditLogSearchRequest;
        options: {
            page: number;
            perPage: number;
        };
    };
    results: AuditLogsSearchResult;
    tables: string[];
};

export const useAuditLogsStore = defineStore("auditLogs", {
    state: (): AuditLogsState => ({
        search: {
            searched: false,
            request: {
                action: AuditedActions.All,
                sortBy: null,
                direction: null,
                tables: null,
                timeRangeStart: null,
                timeRangeEnd: null,
                users: null,
            },
            options: {
                page: 0,
                perPage: 50,
            },
        },
        results: {
            logs: [],
            total: 0,
        },
        tables: [],
    }),
    actions: {
        async searchLogs() {
            const search = this.$state.search;
            const result = await auditLogApi.search(
                search.request,
                search.options.page,
                search.options.perPage
            );

            this.$state.search.searched = true;

            this.$state.results.logs = result.logs;
            this.$state.results.total = result.total;
        },
        async loadTableNames(force = false) {
            if (this.$state.tables.length > 0 && !force) {
                return;
            }

            const tables = await auditLogApi.getTablesNames();
            this.$state.tables = tables;
        },
    },
    debounce: {
        loadTableNames: { ms: 100 },
    },
});
