import xor from "lodash/xor";
import type { ProjectVersion } from "./api/projects";
import type { TicketId, TicketKey } from "./api/tickets";
import { SelectItem } from "./typeUtils";
import { capitalCase } from "change-case";

/** FIFO structure arranged by priority. Larger values come first. */
export class PriorityQueue<T> {
    values: { value: T; priority: number }[];

    constructor() {
        this.values = [];
    }

    enqueue(value: T, priority: number) {
        const index = this.values.findIndex((v) => v.priority < priority);

        if (index == -1) {
            this.values.push({ value, priority });
        } else {
            this.values.splice(index, 0, { value, priority });
        }
    }

    dequeue() {
        return this.values.shift();
    }

    count() {
        return this.values.length;
    }

    isEmpty() {
        return this.count() == 0;
    }
}

export function isDefined<T>(val: T): val is NonNullable<T> {
    return val !== null && val !== undefined;
}

export function humanFileSize(bytes: number, precision = 1) {
    const threshold = 1024;

    if (Math.abs(bytes) < threshold) {
        return bytes + " B";
    }

    const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let u = -1;
    const r = 10 ** precision;

    do {
        bytes /= threshold;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1);

    return bytes.toFixed(precision) + " " + units[u];
}

export function sleep(timeout = 1000) {
    return new Promise<void>((resolve) => setTimeout(resolve, timeout));
}

export function isTicketKey(val: TicketId | TicketKey): val is TicketKey {
    return /^[A-Z]+-\d+$/.test(val);
}

export function debounce<T extends unknown[], U>(inner: (...params: T) => U, ms = 1000) {
    let timer = 0;
    let resolves: ((v: U) => void)[] = [];

    return function (...params: T) {
        window.clearTimeout(timer);

        timer = window.setTimeout(() => {
            const result = inner(...params);
            resolves.forEach((r) => r(result));
            resolves = [];
        }, ms);

        return new Promise<U>((resolve) => resolves.push(resolve));
    };
}

export function debounceByParameters<T extends unknown[], U>(
    inner: (...params: T) => U,
    ms = 1000
) {
    const memory = new Map<string, (...params: T) => Promise<U>>();

    return (...args: T) => {
        const argsString = JSON.stringify(args);
        const memoizedDebounce = memory.get(argsString);

        if (memoizedDebounce) {
            return memoizedDebounce(...args);
        } else {
            const debounced = debounce(inner, ms);
            memory.set(argsString, debounced);
            return debounced(...args);
        }
    };
}

export function relativeTime(date: Date, from: Date = new Date()) {
    const formatter = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" });

    // roughly adapted from momentjs's relative time units
    const units = [
        ["year", 1000 * 60 * 60 * 24 * 320], // >= 320 days
        ["month", 1000 * 60 * 60 * 24 * 25], // >= 25 days
        ["day", 1000 * 60 * 60 * 22], // >= 22 hours
        ["hour", 1000 * 60 * 45], // >= 45 minutes
        ["minute", 1000 * 45], // >= 45 seconds
        ["second", 1000],
    ] as const;

    const elapsed = date.valueOf() - from.valueOf();

    const unit = units.find(([_, v]) => Math.abs(elapsed) > v);
    if (unit) {
        return formatter.format(Math.round(elapsed / unit[1]), unit[0]);
    } else {
        return formatter.format(Math.round(elapsed / 1000), "second");
    }
}

export function getIcons(version: ProjectVersion) {
    const icons: { icon: string; color: string; title: string }[] = [];

    if (version.releaseDate) {
        if (version.releaseDate < new Date()) {
            // shipped
            icons.push({ icon: "mdi-ferry", color: "green", title: "Released" });
        }
    } else {
        if (version.endDate && version.endDate <= new Date()) {
            // late release
            icons.push({ icon: "mdi-clock-alert", color: "red", title: "Late" });
        } else if (version.endDate) {
            // has an end date that hasn't passed yet
            icons.push({ icon: "mdi-clock", color: "blue", title: "Upcoming" });
        }

        if (version.freezeDate && version.freezeDate < new Date()) {
            // past code freeze
            icons.push({ icon: "mdi-snowflake", color: "light-blue", title: "Frozen" });
        }
    }

    return icons;
}

// based on Vuetify's convertToUnit
export function convertToUnit(str: string | number | null, unit = "px") {
    if (str === null || str === "") {
        return undefined;
    } else if (isNaN(+str)) {
        return String(str);
    } else if (!isFinite(+str)) {
        return undefined;
    } else {
        return `${str}${unit}`;
    }
}

export function isItem(v: unknown): v is SelectItem<string> {
    return v instanceof Object && "value" in v && typeof v["value"] === "string";
}

export function arrayEquality(a: unknown[], b: unknown[]): boolean {
    const xordArray = xor(a, b);
    return xordArray.length === 0;
}

export function camelCaseToTitleCase(v: string): string {
    let r = v.replace(
        /^[a-z]|^([A-Z]+)(?=[A-Z]|$)|([A-Z]+)(?=[A-Z]|$)|([A-Z])(?=[a-z]+)/,
        (match: string) => " " + match.toUpperCase()
    );
    if (r[0] == undefined) return "";
    if (r[0] == " ") r = r.slice(1);
    if (r[0] == undefined) return "";
    return r[0].toUpperCase() + r.slice(1);
}

export function humanDate(
    d: Date | string | null,
    formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat("en-US"),
    nullValue: string | null = null
): string | null {
    if (d == null) return nullValue;
    if (typeof d == "string") return formatter.format(new Date(d));
    if (typeof d == "object") return formatter.format(d);
    else return null;
}

export function logicalXOR(lhs: boolean | (() => boolean), rhs: boolean | (() => boolean)) {
    return ((a: boolean, b: boolean) => (a || b) && !(a && b))(
        typeof lhs == "function" ? lhs() : lhs,
        typeof rhs == "function" ? rhs() : rhs
    );
}

// export function enumToSelectItem<ValueType>(
//     enm: { [i: number]: string },
//     zeroIsNull: boolean = false
// ): SelectItem<ValueType | null>[] {
//     const entries = Object.entries(enm)
//         .filter((e) => typeof e[1] == "number")
//         .map((e) => ({
//             title: capitalCase(e[0]),
//             value: e[1] as ValueType,
//             disabled: false,
//         }));

//     return zeroIsNull
//         ? [
//               { title: "Choose One", value: null, disabled: true },
//               ...entries.filter((e) => e.value != 0),
//           ]
//         : entries;
// }

export function enumToSelectItem<T>(
    enm: { [i: string]: number | string },
    zeroIsNull: boolean = false,
    stringValueIsNull?: string
): SelectItem<T | null>[] {
    const entries = Object.entries(enm);
    const last = entries[entries.length - 1];
    if (!last) return [];
    const tp = typeof last[1];
    if (tp == "number") {
        const take = entries.length / 2;
        entries.splice(0, take);
    }

    return [
        ...entries
            .filter((e) =>
                zeroIsNull ? e[1] != 0 : stringValueIsNull ? e[1] != stringValueIsNull : true
            )
            .map((e) => ({
                title: capitalCase(e[0]),
                value: e[1] as T,
                disabled: false,
            })),
    ];
}

export function quickHash(obj: object | null): number {
    const str = JSON.stringify(obj);
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0;
    }
    return hash;
}
