import { defineStore } from "pinia";
import { organizationApi, OrganizationId, Organization } from "@/api/organizations";
import { useTeamsStore } from "./teams";
import { isDefined } from "@/utils";
import { useUsersStore } from "./users";
import type { TeamId } from "@/api/teams";
import type { UserEditModel, UserId } from "@/api/users";

export type OrganizationsState = {
    organizations: Map<OrganizationId, Organization> | undefined;
    teams: Map<OrganizationId, Set<TeamId>> | undefined;
    users: Map<OrganizationId, Map<UserId, { teams: TeamId[]; isExclusive: boolean }>> | undefined;
};

export const useOrganizationsStore = defineStore("organizations", {
    state: (): OrganizationsState => ({
        organizations: undefined,
        teams: undefined,
        users: undefined,
    }),
    getters: {
        get(state) {
            return (id: OrganizationId) => state.organizations?.get(id);
        },
        getTeams(state) {
            const teamStore = useTeamsStore();
            return (id: OrganizationId) =>
                Array.from(state.teams?.get(id)?.values() ?? [])
                    .map((t) => teamStore.get(t))
                    .filter(isDefined);
        },
        getUsers(state) {
            const userStore = useUsersStore();
            return (id: OrganizationId) =>
                Array.from(state.users?.get(id)?.keys() ?? [])
                    .map((u) => userStore.get(u))
                    .filter(isDefined);
        },
        getIsExclusive(state) {
            return (id: OrganizationId, userId: UserId) =>
                Array.from(state.users?.get(id)?.entries() ?? [])
                    .filter(isDefined)
                    .filter((u) => u[0] == userId)
                    .map((u) => u[1].isExclusive)[0] ?? false;
        },
    },
    actions: {
        async createOrganization(name: string) {
            const organization = await organizationApi.create(name);

            if (this.organizations) {
                this.organizations.set(organization.id, organization);
            }

            return organization;
        },

        async load(force = false) {
            await this.loadOrganizations(force);
        },
        async loadOrganizations(force = false) {
            if (!force && this.organizations) {
                return;
            }

            const organizations = await organizationApi.getOrganizations();
            this.organizations = new Map(organizations.map((c) => [c.id, c]));
        },

        async loadOrganization(id: OrganizationId, force = false) {
            if (force || !this.organizations?.has(id)) {
                await this.loadOrganizations(true);
            }

            await this.loadOrganizationTeams(id, force);
            await this.loadOrganizationUsers(id, force);
        },

        async loadOrganizationTeams(id: OrganizationId, force = false) {
            if (!force && this.teams?.has(id)) {
                return;
            }

            if (!this.teams) {
                this.teams = new Map();
            }

            const teamsStore = useTeamsStore();
            await teamsStore.loadTeams(force);

            const teams = await organizationApi.getTeams(id);
            this.teams.set(id, new Set(teams));
        },

        async createTeam(organization: OrganizationId, name: string) {
            const teamsStore = useTeamsStore();
            const team = await organizationApi.createTeam(organization, name);
            await this.loadOrganizationTeams(organization, true);
            await teamsStore.loadTeams(true);

            return team;
        },

        async loadOrganizationUsers(id: OrganizationId, force = false) {
            if (!force && this.users?.has(id)) {
                return;
            }

            if (!this.users) {
                this.users = new Map();
            }

            const userStore = useUsersStore();
            await userStore.load(force);

            const users = await organizationApi.getUsers(id);
            this.users.set(
                id,
                new Map(users.map((u) => [u.id, { teams: u.teams, isExclusive: u.isExclusive }]))
            );
        },

        async addUser(organization: OrganizationId, email: string | null, userName: string | null) {
            const user = await organizationApi.addUser(organization, email, userName);

            const users = this.users?.get(organization);
            if (users) {
                users.set(user.id, { teams: user.teams, isExclusive: user.isExclusive });
            } else {
                await this.loadOrganizationUsers(organization);
            }
        },

        async removeUser(organization: OrganizationId, user: UserId) {
            await organizationApi.removeUser(organization, user);

            const users = this.users?.get(organization);
            if (users) {
                users.delete(user);
            }
        },

        async editUser(organization: OrganizationId, user: UserId, model: UserEditModel) {
            const userStore = useUsersStore();
            const userRes = await organizationApi.editOrganizationUser(organization, user, model);

            await userStore.modifyUser(userRes);
        },
    },
    debounce: {
        loadOrganizations: { ms: 100 },
        loadOrganizationTeams: { ms: 100, byParameters: true },
        loadOrganizationUsers: { ms: 100, byParameters: true },
    },
});
