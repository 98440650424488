import { EmailTemplate, EmailTemplateType, emailApi } from "@/api/emails";
import { defineStore } from "pinia";

type EmailsState = {
    templates: Map<EmailTemplateType, EmailTemplate> | undefined;
};

export const useEmailsStore = defineStore("emails", {
    state: (): EmailsState => ({
        templates: undefined,
    }),
    getters: {
        templateArray: (state) => Array.from(state.templates?.values() ?? []),
    },
    actions: {
        async load(force = false) {
            if (this.templates && !force) {
                return;
            }

            const templates = await emailApi.getTemplates();

            this.templates = new Map(
                Object.values(EmailTemplateType)
                    .filter((t): t is EmailTemplateType => typeof t === "number")
                    .map((type) => [
                        type,
                        templates.find((t) => t.type === type) ??
                            ({
                                type,
                                html: false,
                                subject: "",
                                body: "",
                            } satisfies EmailTemplate),
                    ])
            );
        },
        async edit(type: EmailTemplateType, html: boolean, subject: string, body: string) {
            await emailApi.editTemplate(type, html, subject, body);

            const template = this.templates?.get(type);

            if (!template) {
                this.templates?.set(type, {
                    type,
                    html,
                    subject,
                    body,
                });

                return;
            }

            template.html = html;
            template.subject = subject;
            template.body = body;
        },
    },
    debounce: {
        load: { ms: 100 },
    },
});
